<div class="container-fluid pb-4 full-height d-flex flex-column">
  @if (loading) {
    <div class="row">
      <div class="col-12 text-center">
        <i class="fa-light fa-spinner spinning"></i>
      </div>
    </div>
  } @else {
    <div class="row">
      <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__lightSpeedInLeft">
        <hiji-user-card [user]="data.member" [showDates]="true"></hiji-user-card>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <hr />
      </div>
    </div>

    @if (
      !me && modulesDTO.moduleEMA.enabled && data.kpi.length !== 0 && (data.isInMyGlobalTeam || (data.userActionsEMADTO && data.userActionsEMADTO.isCoach))
    ) {
      <hiji-user-actions-kpi [data]="data.kpi"></hiji-user-actions-kpi>
    }

    @if (!me && modulesDTO.moduleFBS.enabled) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body pointer" (click)="sendFBS()">
            <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center">
              <i class="fa-light fa-comment-alt fa-2x text-primary p-2 me-2 align-icon" aria-hidden="true"></i>
              <span class="my-fs-md-4 my-fs-2 align-text-bottom">
                {{ 'user-actions.Envoyer un feedback spontané' | translate }}
              </span>
            </div>
          </a>
        </div>
      </div>
    }

    @if (!me && modulesDTO.moduleFBS.enabled) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body pointer" (click)="askFBS()">
            <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center">
              <i class="fa-light fa-comment-alt fa-flip-horizontal fa-2x text-primary p-2 me-2 align-icon" aria-hidden="true"></i>
              <span class="my-fs-md-4 my-fs-2 align-text-bottom">
                {{ 'user-actions.Solliciter un feedback spontané' | translate }}
              </span>
            </div>
          </a>
        </div>
      </div>
    }

    @if (me && modulesDTO.module360.enabled && modulesDTO.module360.custom360Allowed) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body pointer" routerLink="/inquiry360/new">
            <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center">
              <i class="fa-light fa-clipboard-list fa-2x text-primary p-2 me-2 align-icon" aria-hidden="true"></i>
              <span class="my-fs-md-4 my-fs-2 align-text-bottom">
                {{ 'user-actions.Solliciter du feedback 360' | translate }}
              </span>
            </div>
          </a>
        </div>
      </div>
    }

    @if (data.campaigns.length === 0 && (data.isInMyTeam || me) && modulesDTO.module180.enabled && modulesDTO.frontConfiguration.showContactHelp) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <div class="bg-white bg-light-30p-hover border rounded-3 p-2 text-center">
            {{ 'campaign-list.Aucune campagne' | translate }}. {{ "campaign-list.Ce n'est pas normal ?" | translate }}
            <br />
            <a
              class="text-info"
              routerLink="/contact/no-campaign-for-{{ idUser }}-{{ string_to_slug(data.member.firstName) }}-{{ string_to_slug(data.member.lastName) }}"
            >
              {{ 'campaign-list.Contactez-nous' | translate }}
            </a>
          </div>
        </div>
      </div>
    }

    @if (
      modulesDTO.moduleEMA.enabled &&
      data.userActionsEMADTO &&
      (me || data.isInMyTeam || (modulesDTO.moduleEMA.allowN2PlusToAuditExchanges && data.isInMyGlobalTeam) || data.userActionsEMADTO.isCoach) &&
      data.userActionsEMADTO.isEMATarget
    ) {
      <hiji-user-actions-ema [data]="data.userActionsEMADTO" [ua]="data"></hiji-user-actions-ema>
    }

    @if (
      modulesDTO.moduleEMA.enabled &&
      (me || data.isInMyTeam || data.isInMyGlobalTeam || (data.userActionsEMADTO && data.userActionsEMADTO.isCoach)) &&
      modulesDTO.moduleEMA.teamExchangeRecapEnabled &&
      data.userActionsEMADTO.team.length > 0
    ) {
      <hiji-user-actions-team [data]="data"></hiji-user-actions-team>
    }

    <hiji-user-actions-campaign180 [data]="data" [me]="me"></hiji-user-actions-campaign180>

    @if (me && !hideVisualize) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body pointer" routerLink="/visualize">
            <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center">
              <i class="fa-light fa-eye fa-2x text-primary p-2 align-icon" aria-hidden="true"></i>
              <span class="my-fs-md-4 align-text-bottom">
                {{ 'user-actions.Visualiser mes informations' | translate }}
              </span>
            </div>
          </a>
        </div>
      </div>
    }

    @if (modulesDTO.modulePDI && (data.isInMyTeam || me)) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body pointer" [routerLink]="['/pdi', data.member.idUser, string_to_slug(data.member.firstName + ' ' + data.member.lastName)]">
            <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center">
              <i class="fa-light fa-graduation-cap fa-2x text-primary p-2 align-icon"></i>
              <span class="my-fs-md-4 align-text-bottom">
                {{
                  me
                    ? ('user-actions.Mon plan de développement individuel' | translate)
                    : ('user-actions.Voir son plan de développement individuel' | translate)
                }}
              </span>
            </div>
          </a>
        </div>
      </div>
    }

    @if (modulesDTO.moduleMIF.enabled) {
      @if (me) {
        <div class="row">
          <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
            <a class="text-body pointer" [routerLink]="['/formation', data.member.idUser, string_to_slug(data.member.firstName + '-' + data.member.lastName)]">
              <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center">
                <i class="fa-light fa-graduation-cap fa-2x text-primary p-2 align-icon"></i>
                <span class="my-fs-md-4 align-text-bottom">
                  {{ 'user-actions.Mes formations' | translate }}
                </span>
              </div>
            </a>
          </div>
        </div>
      }
      @if (!me && data.isInMyTeam) {
        <div class="row">
          <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
            <a class="text-body pointer" [routerLink]="['/formation', data.member.idUser, string_to_slug(data.member.firstName + '-' + data.member.lastName)]">
              <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center">
                <i class="fa-light fa-graduation-cap fa-2x text-primary p-2 align-icon"></i>
                <span class="my-fs-md-4 align-text-bottom">
                  {{ 'user-actions.Formations' | translate }}
                </span>
              </div>
            </a>
          </div>
        </div>
      }
    }

    <hiji-user-actions-pdi [data]="data"></hiji-user-actions-pdi>

    @if (modulesDTO.moduleTeamBoard) {
      <hiji-user-actions-teamboard [data]="data"></hiji-user-actions-teamboard>
    }

    <hiji-user-actions-reset-pwd-pincode [data]="data" [me]="me"></hiji-user-actions-reset-pwd-pincode>

    @if (modulesDTO.moduleGlobal.manualHierarchicalManagement && !me) {
      <div class="row" id="btn-add-remove-team">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          @if (data.isTeamMemberInvitationPending) {
            <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center">
              <i class="fa-light fa-user-clock fa-2x me-2 text-secondary p-2 align-icon"></i>
              <span class="my-fs-md-4 align-text-bottom">
                {{ 'user-actions.En attente de validation par \{\{name\}\}' | translate: { name: data.member.firstName + ' ' + data.member.lastName } }}
              </span>
              <button class="btn btn-outline-medium" type="button" (click)="cancelInvitationUserInMyTeam()">
                {{ "user-actions.Annuler l'ajout" | translate }}
              </button>
            </div>
          } @else if (data.isInMyTeam) {
            <a class="text-body pointer" (click)="removeUserFromMyTeam()">
              <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center text-danger-hover">
                <i class="fa-light fa-times fa-2x me-2 p-2 align-icon"></i>
                <span class="my-fs-md-4 align-text-bottom">
                  {{ 'user-actions.Supprimer de mon équipe' | translate }}
                </span>
                <i class="fa fa-spinner spinning ms-2" *ngIf="loadingTeamMember"></i>
                <div class="row" *ngIf="messageFailedToDelete !== null">
                  <div class="col">
                    <span class="text-danger">
                      {{ messageFailedToDelete }}
                    </span>
                  </div>
                </div>
              </div>
            </a>
          } @else {
            <a class="text-body pointer" (click)="inviteUserInMyTeam()">
              <div class="bg-white bg-light-30p-hover border rounded-3 p-2 d-flex align-items-center">
                <i class="fa-light fa-plus fa-2x me-2 text-secondary p-2 align-icon"></i>
                <span class="my-fs-md-4 align-text-bottom">
                  {{ 'user-actions.Ajouter à mon équipe' | translate }}
                </span>
                <i class="fa fa-spinner spinning ms-2" *ngIf="loadingTeamMember"></i>
              </div>
            </a>
          }
        </div>
      </div>
    }
  }
</div>
